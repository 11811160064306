import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/sefa-celik.png"
import mockup from "../../assets/img/sefastone-mockup.png"
import OtherCaseStudy from "../../components/other-case"
import detail1 from "../../assets/img/sefastone-detail1.png"
import detail2 from "../../assets/img/sefastone-detail2.png"
import detail3 from "../../assets/img/sefastone-detail3.png"

import eelbeyglu from "../../assets/img/teams/11.jpg"
import serife from "../../assets/img/teams/20.jpg"
import tugce from "../../assets/img/teams/03.png"

import onay from "../../assets/img/teams/23.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="Sefa Stone Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Sefa Stone</h1>
                <p>Türk sermayesi ile ABD’de kurulmuş bir mermer firması olan Sefa Stone, kuruluşundan bu yana geçen yıllar içerisinde Miami’nin en büyük mermer firması konumuna gelmiştir. Başta travertine tile (traverten fayans) olmak üzere birçok farklı mermer çeşidi ihraç eden firma ile dijital pazarlama alanında 2015 yılından beri iş birliği yapmaktayız.</p>
                <h2>Sorunlar</h2>
                <ul>
                    <li>&bull; Hedef kelimelerde rekabetten dolayı Google arama motoru sonuçlarında 2. sayfada yer alınması,</li>
                    <li>&bull; Müşterinin sipariş dönüşümü için gereken yönergelerin eksikliği,</li>
                    <li>&bull; Sosyal medyada marka bilinirliğinin zayıf olması.</li>
                </ul>

                <h2>Çözüm</h2>
                <p>Sefa Stone için kullanıcı dostu bir websitesi yapımına başladık. Altyapıyı değiştirip doğru tasarımı uyguladıktan sonra her bir ürün için bilgilendirici içerikler hazırladık. Marka ile sürelen SEO operasyonlarımız neticesinde aylık ortalama 24.000 organik trafik almaya başladık. Aldığımız kelimeler doğrudan müşteriye dönüşümü teşvik eden kelimeler olduğundan müşteri dönüşümleri bir önceki yıla kıyasla %46 oranında artış gösterdi.</p>
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>%46</span>
                        <p>Daha fazla<br />müşteri dönüşümü</p>
                    </div>
                    <div className="box">
                        <span>%277</span>
                        <p>2 yıllık organik trafik<br />artışı.</p>
                    </div>
                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejist:</li>
                            <li>Dijital Pazarlama Uzmanı:</li>
                            <li>Google Ads:</li>
                            <li>SEO:</li>
                            <li>Website Geliştirme:</li>
                        </ul>
                        <ul>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu" />Alaattin Emre Elbeyoğlu</li>
                            <li>Betül Bayraktar</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={tugce} alt="Tuğçe Gör" />Tuğçe Gör</li>
                            <li><img src={onay} alt="Barış Onay" />Barış Onay</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p>“ABD’de birçok ajans ile çalıştık. Genellikle 1. yılın sonunda anlaşmamız netice görmediğimiz için sonlandı. Flatart ile 6 ay içerisinde organik trafikte %60 artış gördük ve devamında 3 yıllık çalışmamızda çok ciddi sonuçlar aldık.”</p>
                <div className="author">
                    <div>
                        <img src={customer} alt="Sefa Çevik"/>
                    </div>
                    <div className="author-name">
                        Sefa Çevik<br />
                        <span>CEO</span>
                    </div>
                </div>
            </div>

            <div className="site-preview">
                <h2>Sefa Stone Başarı Hikayesi<br/> Detaylı Bakış</h2>
                <p>Marka için ilk olarak doğru altyapıya sahip bir websitesi tasarladık.</p>
                <div className="mockup">
                    <img src={mockup} alt="bir laptop ekranında sefastone web sitesi görüntüleniyor." />
                </div>
            </div>

            <div className="customer-content">
                <div className="row-img">
                    <img style={{ borderRadius: 10 }} src={detail1} alt="sefastone.com web sitesine ait analitik grafikleri" />
                    <img style={{ borderRadius: 10, transform: 'translateY(-60px)' }} src={detail2}  alt="sefastone.com web sitesine ait analitik grafikleri" />
                </div>
                <h2>Arama motoru optimizasyonu çalışmaları.
Küresel başarı için olmazsa olmaz bir trafik kaynağı.</h2>

                <p>Global çapta SEO ve SEM hizmeti verdiğimiz Sefa Stone’un mermer ve traverten hakkında hedeflediği kelime grupları yüksek rekabete sahip olduklarından yıklama başına da yüksek maliyete sahipler.  Bu durumda biz de aynı hedef kelimelere yönelik yaptığımız SEO operasyonları sonucunda ortaya çıkardığımız zengin içerikler ile satışı tetikleyen bir yükseliş yarattık ve hedefe yönelik organik trafik sağladık.</p>
                <p>Firmanın SEO operasyonu için ortalama 1000 kelime uzunluğunda 150 adet web sayfası içeriği üretildi. İlgili ürünün nasıl temizlendiği, kullanım tavsiyeleri ve gerçek iç mimarlar ile çalışılmış dekorasyon önerileri ile birlikte her ürün için farklı bir içerik çalışması yapıldı. Yapılan operasyon öncesinde websiteyi günlük ortalama 30 kullanıcı ziyaret ederken SEO çalışmalarından sonra ziyaretçi sayısı günde 470 kullanıcıya ulaştı.</p>
                <p>Off-Page SEO çalışmaları sonucunda sektörün otoriter sitelerinden backlink çıkışları alındı. Bu sayede Sefa Stone websitesinin arama motorlarındaki görünürlüğü ve otoritesi artırılmış oldu.</p>
                <h2>Hedef kitleye en uygun sosyal mecra: Instagram</h2>
                <p>Sefa Stone’un hedef kitlesi, büyük ve müstakil ev sahiplerinden oluşuyor. Tasarım odaklı bir bakış açısı olan, mermer ve traverten seven bu kitleye ulaşmak için en pratik yol ise Instagram. Buradan yola çıkarak biz de büyüme ve erişim odaklı bir sosyal medya stratejisi uyguladık.</p>
                <img style={{ width: '70%', margin: '30px auto' }} src={detail3}  alt="sefastone.com web sitesine ait analitik grafikleri" />
            </div>
        </div>


        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
